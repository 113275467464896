import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'

import { RemixBrowser } from '@remix-run/react'
import {SentryInit} from '~/services/sentry.client'

SentryInit()
startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
